import React, { Fragment, Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import Masonry from "react-masonry-component";
import Header from "./../../Layout/Header";
import Footer2 from "./../../Layout/Footer2";
import PageTitle from "./../../Layout/PageTitle";

//images
import box1 from "./../../../images/gallery/Gallery1.jpg";
import box2 from "./../../../images/gallery/Gallery2.jpg";
import box3 from "./../../../images/gallery/Gallery3.jpg";
import box4 from "./../../../images/gallery/Gallery4.png";
import box5 from "./../../../images/gallery/Gallery5.png";
import box6 from "./../../../images/gallery/Gallery6.png";
import box7 from "./../../../images/gallery/Gallery7.png";
import box8 from "./../../../images/gallery/Gallery8.png";
import box9 from "./../../../images/gallery/Gallery9.png";
import box10 from "./../../../images/gallery/Gallery10.png";
import box11 from "./../../../images/gallery/Gallery11.png";
import box12 from "./../../../images/gallery/Gallery12.png";
import box13 from "./../../../images/gallery/Gallery13.png";
import box14 from "./../../../images/gallery/Gallery14.png";
import box15 from "./../../../images/gallery/Gallery15.png";
import box16 from "./../../../images/gallery/Gallery16.png";

const imageBlog = [
  { Large_img: box1, tag: ["Designing", "Technology", "Artistic"] },
  { Large_img: box2, tag: ["Artistic", "Mockup", "Technology"] },
  { Large_img: box3, tag: ["Technology", "Mockup", "Artistic"] },
  { Large_img: box4, tag: ["Artistic", "Technology"] },
  { Large_img: box5, tag: ["Designing", "Technology", "Artistic"] },
  { Large_img: box6, tag: ["Contruction"] },
  { Large_img: box7, tag: ["Contruction"] },
  { Large_img: box8, tag: ["Contruction", "Mockup", "Technology"] },
  { Large_img: box9, tag: ["Contruction"] },
  { Large_img: box10, tag: ["Contruction", "Mockup"] },
  { Large_img: box11, tag: ["Sample Flats"] },
  { Large_img: box12, tag: ["Sample Flats"] },
  { Large_img: box13, tag: ["Sample Flats"] },
  { Large_img: box14, tag: ["Sample Flats"] },
  { Large_img: box15, tag: ["Sample Flats"] },
  { Large_img: box16, tag: ["Sample Flats"] },
];

// Masonry section
const masonryOptions = {
  transitionDuration: 0,
};

const imagesLoadedOptions = { background: ".my-bg-image-el" };
// Masonry section end

const TagLi = ({ name, handlesettag, tagActive }) => {
  return (
    <li
      className={` tag ${tagActive ? "btn active" : "btn"}`}
      onClick={() => handlesettag(name)}
    >
      <input type="radio" />
      <Link to={"#"} className="site-button-secondry button-skew">
        {" "}
        <span>
          {name} {""}
        </span>{" "}
      </Link>
    </li>
  );
};

class Portfolio2 extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content bg-white">
          {/*  banner  */}
          <PageTitle motherMenu="Gallery" activeMenu="Gallery" />
          {/*  Section-1 Start  */}
          <PortfolioItem />
        </div>
        <Footer2 />
      </Fragment>
    );
  }
}

function PortfolioItem() {
  const [tag, setTag] = useState("Artistic");
  const [filteredImages, setFilterdImages] = useState([]);

  useEffect(() => {
    tag === "ALL"
      ? setFilterdImages(imageBlog)
      : setFilterdImages(
          imageBlog.filter((image) =>
            image.tag.find((element) => element === tag)
          )
        );
  }, [tag]);

  return (
    <>
      <section
        className="content-inner-2"
        // data-content="GALLERY"
      >
        <div className="container">
          <div className="section-head text-center">
            <p>VIEW GALLERY</p>
            <h2 className="title m-b10">Our Best Projects</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="site-filters filter-style1 clearfix m-b20">
                <ul className="filters" data-toggle="buttons">
                  {/* <TagLi
                    name="All"
                    handlesettag={setTag}
                    tagActive={tag === "All" ? true : false}
                  /> */}
                  <TagLi
                    name="Artistic"
                    handlesettag={setTag}
                    tagActive={tag === "Artistic" ? true : false}
                  />
                  <TagLi
                    name="Contruction"
                    handlesettag={setTag}
                    tagActive={tag === "Contruction" ? true : false}
                  />
                  <TagLi
                    name="Sample Flats"
                    handlesettag={setTag}
                    tagActive={tag === "Sample Flats" ? true : false}
                  />
                  {/* <TagLi
                    name="Technology"
                    handlesettag={setTag}
                    tagActive={tag === "Technology" ? true : false}
                  /> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix">
          <ul
            className="gallery mfp-gallery text-center portfolio-bx p-l0 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <Masonry
              className={"my-gallery-class"} // default ''
              options={masonryOptions} // default {}
              disableImagesLoaded={false} // default false
              updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
              imagesLoadedOptions={imagesLoadedOptions} // default {}
            >
              {filteredImages.map((item, index) => (
                <li
                  data-category="abstract"
                  className="card-container col-lg-3 col-md-4 col-sm-6 p-lr0 abstract"
                  key={index}
                >
                  <div className="dlab-media dlab-img-overlay1 dlab-img-effect portbox1">
                    <img src={item.Large_img} alt="" />
                    <div className="overlay-bx">
                      <div className="portinner">
                        <span>July 3, 2016 in Travelling</span>
                        <h3 className="port-title">
                          <Link to={"project-detail-1"}>
                            Design is where science
                          </Link>
                        </h3>
                        <Link
                          to={"/up-coming-projects"}
                          className="btn btn-primary m-t15"
                        >
                          View Project
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </Masonry>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Portfolio2;
