import React, { Fragment, Component } from "react";
import { Helmet } from 'react-helmet';

import WOW from "wowjs";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ScrollAnimation from "react-animate-on-scroll";
import Gallery8 from "./../../../images/main-slider/Gallery8.png";
import Gallery9 from "./../../../images/main-slider/Gallery9.png";

import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import Header from "./../../Layout/Header";
import Footer2 from "./../../Layout/Footer2";
import PageTitle from "./../../Layout/PageTitle";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import modalimage from "./../../../images/banner/modalback.jpg";
import LogoWhite from "../../../images/logo1.png";
//images
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VideoPopup from "./../../Element/VideoPopup";

import mainbanner from "./../../../images/main-slider/Gallery14.png";

//Light Gallery on icon click
/* const Iconimage = props => {
  const { openLightbox } = useLightbox()
	
  return (
    
  )
} */

const FAQs = {
  "What makes Khetan Swiss Gardens unique among 3 BHK Flats in Mohali?":
    "Khetan Swiss Gardens stands out with its Swiss Architectural Design, Luxurious Amenities, and Strategic Location on Airport Road, Mohali.",
  "Is Khetan Swiss Gardens a RERA-approved project?":
    "Yes, Khetan Swiss Gardens is a RERA-approved project ensuring transparency and reliability for all homebuyers in Mohali.",
  "Why invest in Mohali?":
    "Mohali offers tremendous growth opportunities in real estate, particularly in 3 BHK apartments and flats. With its strategic location and evolving infrastructure, Mohali promises high ROI and a quality lifestyle.",
  "What is the location advantage of Khetan Swiss Gardens?":
    "Khetan Swiss Gardens is located in Mohali, offering proximity to key areas like - Max Hospital, International Airport, International Business School and more.",
  "Is there legal assistance available for homebuyers?":
    "Yes, Khetan Swiss Gardens provides legal assistance for a smooth and transparent buying process and has partnered with top-tier banks like IDBI Bank, ICICI Bank, HDFC Bank, PHFL, Canara Bank, Tata Capital and PGB to ease out the Home Loan process.",
};

const GalleryBox = ({ imageBlog }, props) => {
  const { openLightbox } = useLightbox();
  return (
    <>

      <div className="dlab-media dlab-img-overlay1 overlay-primary">
        <img src={imageBlog} alt="" />
        <div className="overlay-bx">
          <div className="overlay-icon">
            <a
              // href="https://www.youtube.com/watch?v=FALUYc5eX9s"
              href="https://www.youtube.com/watch?v=cxaaaLz6a7k"
              //
              className="popup-youtube"
            >
              <i className="fa fa-play icon-bx-xs"></i>
            </a>
            <a onClick={() => openLightbox(props.imageToOpen)} className=" ">
              <i className="fa fa-search icon-bx-xs"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

class SplitSection extends Component {
  render() {
    const items = [
      "Swimming Pool",
      "Gymnasium",
      "Luxury Clubhouse",
      "Indoor & Outdoor Games",
      "Open Air Theater",
      "Recreational Area",
      "Centre Plaza",
      "Outdoor Landscape",
      "Cafeteria",
    ];

    return (
      //   <Grid container spacing={2} style={{ padding: 20 }}>
      //     <Grid item xs={12} sm={6}>
      <ScrollAnimation
        animateIn="bounceInLeft"
        duration="2"
        animatePreScroll={false}
        animateOnce={true}
      >
        <Helmet>
          <title>Khetan realty - 3bhk flats in mohal</title>
          <meta name="description" content="Khetan Swiss Gardens offers Modern 3BHK Flats & Apartments in Mohali, Airport Road with stunning architecture and amenities" />
          <meta name="keywords" content="3BHK, Flats, Apartment, Khetan Swiss Gardens" />
        </Helmet>

        <h2
          className="title"
          style={{ textTransform: "uppercase", fontSize: "1.4em" }}
        >
          3 BHK Flats & Apartments with Luxurious Amenities in Mohali{" "}
        </h2>
        <p style={{ fontSize: "1em", fontWeight: "300" }}>
          Designed meticulously with a Swiss Architectural Concept, Khetan
          Swiss Gardens offers 3 BHK Apartments in Mohali equipped with
          highly functional amenities and nestled conveniently on Airport
          Road, Mohali
        </p>
        <List>
          {items.map((item, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <i
                  className="fa fa-check-circle"
                  style={{ color: "#263d63", fontSize: "1.5em" }}
                ></i>
                {/* <CheckCircleIcon /> */}
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </ScrollAnimation>
      // </Grid>

      // {/* <Grid item xs={12} sm={6}>
      //   <ScrollAnimation animateIn="bounceInRight" duration="2" animateOnce={true}  animatePreScroll={false}>
      //     <img src={SwimmingImg} alt="Placeholder" style={{ width: '100%', height: 'auto' }} />
      //     </ScrollAnimation>
      // </Grid> */}
      //   </Grid>
    );
  }
}

class FlatsthreeBHk extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      userName: "",
      userNumber: "",
      userEmail: "",
      errorMessage: "",
    };
  }
  handleClose = () => {
    this.setState({ showModal: false });
  };
  handleOpen = () => {
    this.setState({ showModal: true });
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  render() {
    const { showModal, userName, userNumber, userEmail, errorMessage } = this.state;
    return (
      <Fragment>
        <Header />
        <div className="page-content bg-white">
          {/*  banner  */}
          <PageTitle
            motherMenu="Khetan Swiss Gardens"
            activeMenu="3 BHK Flats in Mohali"
          />
          {/*  Section-1 Start  */}
          <Modal size="lg" centered show={showModal} onHide={this.handleClose}>
            <div>
              <div className="row">
                <div className="col-md-6" style={{ padding: "0px" }}>
                  <div
                    style={{
                      backgroundImage: `url(${modalimage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                </div>
                <div className="col-md-6" style={{ paddingLeft: "0px", backgroundColor: "#263d63" }}>
                  <Modal.Header closeButton>
                    <img src={LogoWhite} className="img-fluid mt-4" style={{ margin: "0 auto" }} width="200px" />
                  </Modal.Header>
                  <Modal.Body className="text-center">
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group className="mb-3 mt-4" controlId="formUserName">
                        <Form.Control
                          type="text"
                          className="modal-input"
                          placeholder="Your name*"
                          name="userName"
                          value={userName}
                          onChange={this.handleChange}
                          required
                          autoFocus
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formUserNumber">
                        <Form.Control
                          type="text"
                          className="modal-input"
                          placeholder="Your number*"
                          name="userNumber"
                          value={userNumber}
                          onChange={this.handleChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formUserEmail">
                        <Form.Control
                          type="email"
                          className="modal-input"
                          placeholder="Your Email*"
                          name="userEmail"
                          value={userEmail}
                          onChange={this.handleChange}
                          required
                        />
                      </Form.Group>
                      {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}
                      <Button type="submit" variant="primary" style={{ width: "100%", backgroundColor: "#5d80b9" }}>
                        Submit
                      </Button>
                    </Form>
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
          <section
            className="content-inner about-box"
            // data-content="ON GOING PROJECT"
            id="sidenav_aboutUs"
          >
            {/* <div className="about-bg"></div> */}

            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <ScrollAnimation
                    animateIn="bounceInLeft"
                    duration="3"
                    animateOnce={true}
                    animatePreScroll={false}
                  >
                    <img src={Gallery8} className="img-fluid" />
                  </ScrollAnimation>
                </div>

                <div className="col-md-6 col-lg-6">
                  <ScrollAnimation
                    animateIn="bounceInRight"
                    duration="3"
                    animateOnce={true}
                    animatePreScroll={false}
                  >
                    <div className="section-head">
                      <h2 className="title" style={{ fontSize: "1.7em" }}>
                        3 BHK Flats in Mohali
                      </h2>
                      <p> Mohali Punjab </p>
                      <div className="dlab-separator bg-primary "></div>
                      <p style={{ fontSize: "1em", fontWeight: "300" }}>
                        Khetan Swiss Gardens is a RERA-approved project located
                        strategically in Mohali that exudes luxury and
                        togetherness. The Majestic Architecture of 3 BHK
                        Apartments and its stunning amenities create an
                        enriching environment for all. The 3 BHK Apartments in
                        Mohali are thoughtfully designed as per Vaastu Shastra
                        and has a dynamic structure with seven towers, each
                        comprising Stilt + 14 Floors. Additionally, one can
                        enjoy an ultra-modern lifestyle built to harmonise with
                        its surroundings.
                      </p>
                      <div target="_blank" rel="noopener noreferrer" onClick={() => this.handleOpen()} className="btn btn-primary m-responsive">
                        Contact us
                      </div>
                    </div>


                    {/* <a  target="_blank" rel="noopener noreferrer" href={BrochurePDF} download className="btn btn-primary m-responsive">
                    Download Brochure
                  </a> */}
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">


                <div className="col-md-6 col-lg-6">
                  <ScrollAnimation
                    animateIn="bounceInLeft"
                    duration="3"
                    animateOnce={true}
                    animatePreScroll={false}
                  >
                    <div className="section-head">
                      <h2 className="title" style={{ fontSize: "1.7em" }}>
                        3 BHK + Store Apartments in Mohali
                      </h2>
                      <p> Mohali Punjab </p>
                      <div className="dlab-separator bg-primary "></div>
                      <p style={{ fontSize: "1em", fontWeight: "300" }}>
                        Live your dream life amidst a harmonious blend of urban comfort and natural serenity with Khetan Swiss Gardens’s 3 BHK Flats with Store in Mohali. Experience the essence of smart living that embodies greenery, modernity and ease of connectivity. These thoughtfully designed flats / apartments with stores redefine premium living offering a balanced lifestyle in the heart of Mohali.
                      </p>
                      <div target="_blank" rel="noopener noreferrer" onClick={() => this.handleOpen()} className="btn btn-primary m-responsive">
                        Contact us
                      </div>
                    </div>


                    {/* <a  target="_blank" rel="noopener noreferrer" href={BrochurePDF} download className="btn btn-primary m-responsive">
                    Download Brochure
                  </a> */}
                  </ScrollAnimation>
                </div>
                <div className="col-md-6 col-lg-6">
                  <ScrollAnimation
                    animateIn="bounceInRight"
                    duration="3"
                    animateOnce={true}
                    animatePreScroll={false}
                  >
                    <img src={Gallery9} className="img-fluid" />
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            {/* <SplitSection /> */}
          </section>
          <div className="container">
            <SplitSection />
          </div>
          <div className="container">
            <h2 className="title" style={{ fontSize: "1.7em" }}>
              FAQ's{" "}
            </h2>
            {Object.entries(FAQs).map(([key, value]) => {
              return (
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDownwardIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography>{key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{value}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </div>
          {/* <div
            className="banner-three"
            style={{
              backgroundImage: "url(" + mainbanner + ")",
              backgroundSize: "cover",
              // marginBottom: "5.2rem",
            }}
            data-content="HOME"
            id="sidenav_home"
          >
            <div
              className="container"
              style={{
                // position: "absolute",
                top: "10rem",
                left: "10rem",
                zIndex: "15",
              }}
            >
              <div className="row align-items-center banner-inner text-center">
                <div className="col-md-12">
                  <ScrollAnimation
                    animateIn="bounceInUp"
                    duration="3"
                    animateOnce={true}
                  >
                    <div className="content-blog" style={{ marginLeft: "0px" }}>
                      <div
                        className="banner-content"
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          width: "100%",
                          height: "100%",
                          padding: "20px",
                        }}
                      >
                    
                      </div>
                      <VideoPopup />
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <Footer2 />
      </Fragment>
    );
  }
}
export { GalleryBox };
export default FlatsthreeBHk;
