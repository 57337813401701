import React from "react";

/// Components
import Markup from "./markup/Markup";
import "animate.css/animate.compat.css"

/// Style
import "./vendor/bootstrap-select/bootstrap-select.min.css";
import "./vendor/animate/animate.css";
import "./css/style.css";
import "./vendor/slick/slick.min.css";
import "./vendor/slick/slick-theme.min.css";
import "react-modal-video/css/modal-video.min.css";
import Enquiry from "./markup/Element/Enquiry";
import Associate from "./markup/Element/Associate";
import withDisableRightClick from "./markup/Element/withDisableRightClick";

import { withResizeDetector } from "react-resize-detector";
function App() {
  return (
    <div className="App">
      {/* <Enquiry /> */}
      {/* <Associate /> */}
      <Markup />
    </div>
  );
}

// export default withResizeDetector(withDisableRightClick(App)); enable right click
export default withResizeDetector(App);
