// src/components/withDisableRightClick.js

import React, { useEffect } from 'react';

const withDisableRightClick = (WrappedComponent) => {
  return (props) => {
    useEffect(() => {
      const handleContextMenu = (event) => {
        event.preventDefault();
      };

      document.addEventListener('contextmenu', handleContextMenu);

      // Cleanup listener on unmount
      return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
      };
    }, []);

    return <WrappedComponent {...props} />;
  };
};

export default withDisableRightClick;
