import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";

import Header2 from "./../../Layout/Header2";
import Footer2 from "./../../Layout/Footer2";
import SideNav from "./../../Element/SideNav";
import VideoPopup from "./../../Element/VideoPopup";

import Slider1 from "./../../Element/Slider1";
import Mapview from "./../../Element/Mapview";
import EventSection from "./EventSection";
import SpecificationsBlog from "./SpecificationsBlog";
import SitePlanTab from "./SitePlanTab";

//Images
import SliderImg from "./../../../images/amenities/slider1.png";
import main1 from "./../../../images/gallery/main1.jpg";

import spec1 from "./../../../images/specifications/pic1.jpg";
import spec2 from "./../../../images/specifications/pic2.jpg";
import spec3 from "./../../../images/specifications/pic3.jpg";

import main2 from "./../../../images/gallery/main2.jpg";
import main3 from "./../../../images/gallery/main3.jpg";
import main4 from "./../../../images/gallery/main4.jpg";
import main5 from "./../../../images/gallery/main5.jpg";
import main6 from "./../../../images/gallery/main6.jpg";

import gallery1 from "./../../../images/gallery/pic1.jpg";
import gallery2 from "./../../../images/gallery/pic2.jpg";
import gallery3 from "./../../../images/gallery/pic3.jpg";
import gallery4 from "./../../../images/gallery/pic4.jpg";
import gallery5 from "./../../../images/gallery/pic5.jpg";
import gallery6 from "./../../../images/gallery/pic6.jpg";

import services1 from "./../../../icons/etcicon/sheid.svg";
import services2 from "./../../../icons/etcicon/inno.svg";
import services3 from "./../../../icons/etcicon/cult.svg";
import services4 from "./../../../icons/etcicon/smart.svg";
import services5 from "./../../../icons/etcicon/thunder.svg";
import services6 from "./../../../icons/etcicon/degree.svg";
// import services7 from "./../../../images/services/pic7.jpg";
// import services8 from "./../../../images/services/pic8.jpg";
// import services9 from "./../../../images/services/pic9.jpg";
// import services10 from "./../../../images/services/pic10.jpg";
const aboutBlog = [
  {
    icon: <i className="ti-rocket" />,
    title: "Client Driven Approach",
    message: "A Journey For the Client; With the Client",
  },
  {
    icon: <i className="ti-pie-chart" />,
    title: "Innovation & Dynamism",
    message: "Constantly Striving Towards Futuristic Establishments",
  },
  {
    icon: <i className="ti-ruler-pencil" />,
    title: "Cultural and Artistic Integration",
    message: "Bringing International & Colloquial Spaces all at One Place",
  },
  {
    icon: <i className="ti-light-bulb" />,
    title: "Smart Tech Integration",
    message: "Modern Technology for a Leisure & Stylish Lifestyle",
  },
  {
    icon: <i className="ti-home" />,
    title: "Wellness Amenities",
    message: "Endowed with Facilities that Define an Active Lifestyle",
  },
  {
    icon: <i className="ti-brush-alt" />,
    title: "360 Degree Assistance",
    message: "All-inclusive Services & Comfort under One Roof",
  },
];

class Project1 extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();

    var GalleryCategory = document.querySelectorAll(".gallery-category .items");
    var GalelryMedia = document.querySelectorAll(".gallery-img img");

    var fch = [].slice.call(GalleryCategory);
    var fcMedia = [].slice.call(GalelryMedia);

    for (var y = 0; y < fch.length; y++) {
      fch[y].addEventListener("mouseenter", function () {
        galleryActive(this);
      });
    }

    function galleryActive(current) {
      fcMedia.forEach((el) => el.classList.remove("active"));

      setTimeout(() => {
        var dataImageBx = current.getAttribute("data-image-bx");
        document.querySelector("#" + dataImageBx).classList.add("active");
      }, 100);
    }
  }
  render() {
    return (
      <Fragment>
        <div className="sidenav-list" style={{ display: "block" }}>
          <SideNav />
        </div>

        <Header2 />
        {/* <!-- Main Slider --> */}
        <div className="page-content bg-white">
          <div
            className="banner-three "
            style={{
              backgroundImage: "url(" + SliderImg + ")",
              backgroundSize: "cover",
            }}
            data-content="HOME"
            id="sidenav_home"
          >
            <div className="container">
              <div className="row align-items-center banner-inner ">
                <div className="col-md-6">
                  <div className="content-blog">
                    <div className="banner-content">
                      <h1 className=" title  m-b20">
                        Helping you and your
                        <br />
                        house become better
                        <br />
                        acquainted{" "}
                      </h1>
                      <Link
                        to={"/contact-us"}
                        className="wow fadeInUp  btn btn-primary"
                      >
                        Book A Site Visit
                      </Link>
                    </div>
                    <VideoPopup />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Main Slider End--> */}

          {/* Section-1  Start*/}
          <section className="amenities-area">
            <Slider1 />
          </section>
          {/* Section-1  End*/}

          {/* Section-2  Start*/}
          {/* <section
            className="content-inner-2"
            data-content="SPECIFICATIONS"
            id="sidenav_specifications"
          >
            <div className="container">
              <div className="section-head head-col">
                <h2 className="title">Specifications</h2>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form
                </p>
              </div>
            </div>
            <div className="row faqs-box spno">
              <div className="col-md-8 col-lg-8 col-xl-8">
                <div className="faq-media">
                  <Link to={"#"}>
                    <img src={spec1} id="Capmap1" className="active" alt="" />
                  </Link>
                  <Link to={"#"}>
                    <img src={spec2} id="Capmap2" alt="" />
                  </Link>
                  <Link to={"#"}>
                    <img src={spec3} id="Capmap3" alt="" />
                  </Link>
                </div>
              </div>
              <SpecificationsBlog />
            </div>
          </section> */}
          {/* Section-2 End */}

          {/* Section-3 */}

          <section
            className="content-inner about-box"
            data-content="ABOUT US"
            id="sidenav_aboutUs"
          >
            <div className="about-bg"></div>
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-lg-6">
                  <div className="section-head">
                    <h2 className="title">Ongoing Project</h2>
                    <div className="dlab-separator bg-primary "></div>
                    <p>
                      Khetan Swiss Gardens are Vastu Compliant 2 & 3 BHK
                      Apartments with Ultra Modern Amenities and a striking S+
                      14 Structure that is Strategically Located at Sector 126,
                      Airport Road, Mohali & Embraces Community Living while
                      providing easy access to Mohali's Major Hotspots.
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span>
                        <h4 className="mb-4">Swiss Architecture</h4>
                        <p>A Fusion of Global Expertise with Indian Values</p>
                      </span>
                      <span>
                        <h4 className="mb-4">Ample Conveniences</h4>
                        <p>
                          Functionality Intertwined with Many Luxurious
                          Amenities
                        </p>
                      </span>
                    </div>
                  </div>
                  <Link to={"#"} className="btn btn-primary ">
                    Invest now
                  </Link>
                </div>
                <div className="col-md-5 col-lg-6"></div>
              </div>
            </div>
          </section>
          <section
            className="content-inner about-box"
            data-content=""
            id="sidenav_aboutUs"
          >
            <div className="about-bg1"></div>
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-lg-6">
                  <div className="section-head">
                    <h2 className="title">KHETAN MALL RD</h2>
                    {/* <div className="dlab-separator bg-primary "></div> */}
                    <p>
                      Khetan Mall RD is a Vivid Vibrant Commercial Street that
                      buzzes with its rhythm, a perfect habitat for all the
                      modern brands. This Architectural Marvel will be the Hub
                      of Commercial Activities and is a spot for Never-ending
                      Entertainment and an Immersive Shopping Experience. Pace
                      up with the tempo of life and engulf into the fun at this
                      prime location. The project aims to attract students,
                      youth and the GenZ to call this the coolest hangout spot.
                      The Mall RD will offer a wide range of offerings such as
                      Retail Shops, Brand Stores, Shops for Mobile Accessories &
                      Electronic Gadgets, Cafés & Food Joints and much more.
                    </p>
                  </div>
                  <Link to={"#"} className="btn btn-primary ">
                    Invest now
                  </Link>
                </div>
                <div className="col-md-5 col-lg-6"></div>
              </div>
            </div>
          </section>
          <section
            className="content-inner about-box"
            data-content=""
            id="sidenav_aboutUs"
          >
            <div className="about-bg2"></div>
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-lg-6">
                  <div className="section-head">
                    <h2 className="title">KHETAN ENGLISH COUNTY</h2>
                    {/* <div className="dlab-separator bg-primary "></div> */}
                    <p>
                      Khetan English County, is an Exquisite Well-planned
                      Township that will be a Testament to Luxurious Living in
                      Mohali’s Sector 113 Locality. The project promises a Haven
                      of Serenity and Security and is set against the backdrop
                      of Pristine Natural Surroundings that will boast
                      State-of-the-Art Infrastructure and Opulent Amenities.
                    </p>
                  </div>
                  <Link to={"#"} className="btn btn-primary ">
                    Invest now
                  </Link>
                </div>
                <div className="col-md-5 col-lg-6"></div>
              </div>
            </div>
          </section>
          <section
            className="content-inner about-box"
            data-content=""
            id="sidenav_aboutUs"
          >
            <div className="about-bg3"></div>
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-lg-6">
                  <div className="section-head">
                    <h2 className="title">KHETAN PARADISE 85</h2>
                    {/* <div className="dlab-separator bg-primary "></div> */}
                    <p>
                      Khetan Paradise 85 is the Monumental Venture that gave
                      Khetan Realty a kickstack in the Real Estate Industry.
                      Nestled in the well-inhabited Sector 85 of Wave Estate,
                      Mohali, these Independent Apartments are the epitome of
                      Classic Design fused with Contemporary Flair clubbed with
                      Lavish Amenities. With over 80 Families already making it
                      their Home, the project has set a New Standard of
                      Excellence.
                    </p>
                  </div>
                  <Link to={"#"} className="btn btn-primary ">
                    Invest now
                  </Link>
                </div>
                <div className="col-md-5 col-lg-6"></div>
              </div>
            </div>
          </section>
          {/* Section-3 End*/}
          {/* Section-4 Start */}
          {/* <section className="content-inner" data-content="MASTER PLAN" id="sidenav_masterPlan">				
						<SitePlanTab />
					</section> */}
          {/* Section-4 End*/}
          {/* Section-5 Start*/}
          {/* <section id="sidenav_mainGallery">
            <div className="main-gallery">
              <div className="gallery-img">
                <img src={main1} id="structureOne" className="active" alt="" />
                <img src={main2} id="structureTow" alt="" />
                <img src={main3} id="kitchenOne" alt="" />
                <img src={main4} id="kitchenTow" alt="" />
                <img src={main5} id="kitchenThree" alt="" />
                <img src={main6} id="interiorOne" alt="" />
              </div>
              <div className="gallery-area">
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={gallery1} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">Structure</h3>
                      <span>
                        <i className="las la-image"></i> 02
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="structureOne">
                      <div className="media">
                        <img src={gallery1} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="structureTow">
                      <div className="media">
                        <img src={gallery2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={gallery1} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">Kitchen</h3>
                      <span>
                        <i className="las la-image"></i> 03
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="kitchenOne">
                      <div className="media">
                        <img src={gallery3} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="kitchenTow">
                      <div className="media">
                        <img src={gallery4} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="kitchenThree">
                      <div className="media">
                        <img src={gallery5} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={gallery3} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">Interior Finish</h3>
                      <span>
                        <i className="las la-image"></i> 01
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="interiorOne">
                      <div className="media">
                        <img src={gallery6} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={gallery4} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">bedroom interior</h3>
                      <span>
                        <i className="las la-image"></i> 04
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="interiorOne">
                      <div className="media">
                        <img src={gallery6} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="kitchenOne">
                      <div className="media">
                        <img src={gallery3} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="kitchenTow">
                      <div className="media">
                        <img src={gallery4} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="interiorOne">
                      <div className="media">
                        <img src={gallery6} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={gallery5} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">Window</h3>
                      <span>
                        <i className="las la-image"></i> 02
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="interiorOne">
                      <div className="media">
                        <img src={gallery6} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="kitchenTow">
                      <div className="media">
                        <img src={gallery4} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* Section-5 End*/}
          {/* Section-6 Start*/}
          <section
            className="content-inner-2"
            data-content="OUR SERVICES"
            id="sidenav_ourServices"
          >
            <div className="container">
              <div className="row align-items-end section-head">
                <div className="col-md-6">
                  <h2 className="title">Amazing Features</h2>
                  <div className="dlab-separator bg-primary"></div>
                  <p className="mb-3 mb-md-0">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form
                  </p>
                </div>
                <div className="col-md-6 text-md-right">
                  <Link to={"./blog-grid"} className="btn btn-primary">
                    View All Services
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="service-area"
              style={{ backgroundImage: "url(" + main1 + ")" }}
            >
              <div className="row spno service-row">
                <div className="col ">
                  <div className="service-box">
                    <div className="media">
                      <img src={services1} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">Client Driven Approach</h4>
                      </Link>
                      <p>A Journey For the Client; With the Client</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="service-box">
                    <div className="media">
                      <img src={services2} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">Innovation & Dynamism</h4>
                      </Link>
                      <p>
                        Constantly Striving Towards Futuristic Establishments
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col ">
                  <div className="service-box">
                    <div className="media">
                      <img src={services3} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">
                          Cultural and Artistic Integration
                        </h4>
                      </Link>
                      <p>
                        Bringing International & Colloquial Spaces all at One
                        Place
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col ">
                  <div className="service-box">
                    <div className="media">
                      <img src={services4} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">Smart Tech Integration</h4>
                      </Link>
                      <p>Modern Technology for a Leisure & Stylish Lifestyle</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="service-box">
                    <div className="media">
                      <img src={services5} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">Wellness Amenities</h4>
                      </Link>
                      <p>
                        Endowed with Facilities that Define an Active Lifestyle
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="service-box">
                    <div className="media">
                      <img src={services6} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">360 Degree Assistance</h4>
                      </Link>
                      <p>All-inclusive Services & Comfort under One Roof</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Section-6 End*/}
          {/* Section-7 Start*/}
          {/* <section
            className="content-inner-2"
            data-content="NEWS & EVENT"
            id="sidenav_newsEvent"
          >
            // <EventSection />
          </section> */}
          {/* Section-7 End*/}

          {/* <Mapview /> */}
        </div>
        <Footer2 />
      </Fragment>
    );
  }
}

export default Project1;
