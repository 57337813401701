import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import { Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@material-ui/core';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ScrollAnimation from 'react-animate-on-scroll';

import SwimmingImg from "../../../images/Swimming.jpg"

import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import Header from "./../../Layout/Header";
import Footer2 from "./../../Layout/Footer2";
import PageTitle from "./../../Layout/PageTitle";
//images
import Gallery8 from "./../../../images/main-slider/Gallery8.png";

import BrochurePDF from "../../../images/Swiss_Garden_Brochure.pdf"
import VideoPopup from "./../../Element/VideoPopup";
import { Helmet } from "react-helmet";

import port1 from "./../../../images/portfolio/portfolio-box/pic1.jpg";
import port2 from "./../../../images/portfolio/portfolio-box/pic2.jpg";
import port3 from "./../../../images/portfolio/portfolio-box/pic3.jpg";
import port4 from "./../../../images/portfolio/portfolio-box/pic4.jpg";
import port5 from "./../../../images/portfolio/portfolio-box/pic5.jpg";
import port6 from "./../../../images/portfolio/portfolio-box/pic6.jpg";
import port7 from "./../../../images/portfolio/portfolio-box/pic7.jpg";

import mainbanner from "./../../../images/main-slider/Gallery14.png";


//Light Gallery on icon click
/* const Iconimage = props => {
  const { openLightbox } = useLightbox()
	
  return (
    
  )
} */

const GalleryBox = ({ imageBlog }, props) => {
  const { openLightbox } = useLightbox();
  return (
    <div className="dlab-media dlab-img-overlay1 overlay-primary">
      <img src={imageBlog} alt="" />
      <div className="overlay-bx">
        <div className="overlay-icon">
          <a
            // href="https://www.youtube.com/watch?v=FALUYc5eX9s"
            href="https://www.youtube.com/watch?v=cxaaaLz6a7k"
            // 
            className="popup-youtube"
          >
            <i className="fa fa-play icon-bx-xs"></i>
          </a>
          <a onClick={() => openLightbox(props.imageToOpen)} className=" ">
            <i className="fa fa-search icon-bx-xs"></i>
          </a>
        </div>
      </div>
    </div>
  );
};


class SplitSection extends Component {
  render() {
    const items = ['Swimming Pool', 'Gymnasium', 'Luxury Clubhouse', ' Indoor & Outdoor Games', ' Open Air Theater', 'Recreational Area', 'Centre Plaza', 'Outdoor Landscape', 'Cafeteria'];

    return (
      <Grid container spacing={2} style={{ padding: 20 }}>

        
        <Grid item xs={12} sm={6}>
        <ScrollAnimation animateIn="bounceInLeft" duration="2"  animatePreScroll={false} animateOnce={true}>
        <h2 className="title" style={{textTransform: 'uppercase', fontSize: '1.4em'}}>Amenities - Khetan Swiss Garden</h2>
            <List>
              {items.map((item, index) => (
                <ListItem key={index}>
                <ListItemIcon>
                    <i className="fa fa-check-circle" style={{ color: '#263d63', fontSize: '1.5em' }}></i>
                  {/* <CheckCircleIcon /> */}
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
              ))}
            </List>
            </ScrollAnimation>
        </Grid>

         <Grid item xs={12} sm={6}>
         <ScrollAnimation animateIn="bounceInRight" duration="2" animateOnce={true}  animatePreScroll={false}>
            <img src={SwimmingImg} alt="Placeholder" style={{ width: '100%', height: 'auto' }} />
            </ScrollAnimation>
        </Grid>
        
       
      </Grid>
    );
  }
}



class Portfolio1 extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    return (
      <Fragment>
        <Helmet>
					<title>Khetan realty - On going project</title>
					<meta name="KHETAN SWISS GARDEN Mohali | 2BHK & 3BHK Apartments" content="Explore KHETAN SWISS GARDEN in Mohali for luxurious 2BHK, 3BHK, & 3BHK+ store apartments. Discover premium living spaces designed for comfort & style." />
				</Helmet>
        <Header />
        <div className="page-content bg-white">
          {/*  banner  */}
          <PageTitle
            motherMenu="ON GOING PROJECT"
            activeMenu="ON GOING PROJECT"
          />
          {/*  Section-1 Start  */}
          <section
            className="content-inner about-box"
            // data-content="ON GOING PROJECT"
            id="sidenav_aboutUs"
          >
           
            {/* <div className="about-bg"></div> */}
            
            <div className="container">
              <div className="row">
           
                <div className="col-md-6 col-lg-6">
                <ScrollAnimation animateIn="bounceInLeft" duration="3" animateOnce={true} animatePreScroll={false}>
                  <img src={Gallery8} className="img-fluid" />
                  </ScrollAnimation>
                </div>

                <div className="col-md-6 col-lg-6">
                <ScrollAnimation animateIn="bounceInRight" duration="3" animateOnce={true} animatePreScroll={false}>
           
                  <div className="section-head">
                    <h2 className="title" style={{fontSize: '1.7em'}}>Khetan Swiss Garden</h2>
                    <p> Mohali Punjab </p>
                    <div className="dlab-separator bg-primary "></div>
                    <p
                     style={{fontSize: '1em', fontWeight: '300',}}
                    >
                    It is an ongoing RERA-approved project namely “Khetan Swiss Gardens” with registered Rera Number (PBRERA-SAS81-PR0827) it is strategically located at Sector 126, Greater Mohali, SAS Nagar, Punjab is well-designed with majestic architecture and features stunning amenities.  The project offers Vaastu-friendly homes stepping into an enriching environment It is coming up with 2BHK, 3BHK & 3BHK+ Store apartments with different sizes. It has seven towers, and each tower has Stilt+14 floors. It is provided with Club House and other ultra-modern amenities which gives the ultimate living experience. 
                    </p>
                    {/* <div style={{ display: "flex", flexDirection: "row" }}>
                      <span>
                        <h4 className="mb-4">Swiss Architecture</h4>
                        <p>A Fusion of Global Expertise with Indian Values</p>
                      </span>
                      <span>
                        <h4 className="mb-4">Ample Conveniences</h4>
                        <p>
                          Functionality Intertwined with Many Luxurious
                          Amenities
                        </p>
                      </span>
                    </div> */}
                  </div>
                  
                  <a  target="_blank" rel="noopener noreferrer" href={BrochurePDF} download className="btn btn-primary m-responsive">
                    Download Brochure
                  </a>
                  </ScrollAnimation>
                 
                 </div>
           
              </div>

              
            </div>
            {/* <SplitSection /> */}

          </section>
          <div className="container">

            <SplitSection />
          </div>
            <div
            className="banner-three"
            style={{
              backgroundImage: "url(" + mainbanner + ")",
              backgroundSize: "cover",
              // marginBottom: "5.2rem",
            }}
            data-content="HOME"
            id="sidenav_home"
          >
            <div
              className="container"
              style={{
                // position: "absolute",
                top: "10rem",
                left: "10rem",
                zIndex: "15",
              }}
            >
              <div className="row align-items-center banner-inner text-center">
                <div className="col-md-12">
                  <ScrollAnimation animateIn="bounceInUp" duration="3" animateOnce={true}>
                    <div className="content-blog" style={{marginLeft: '0px'}}>
                      <div
                        className="banner-content"
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          width: "100%",
                          height: "100%",
                          padding: "20px",
                        }}
                      >
                        {/* <h1
                          className="title m-b20"
                          style={{
                            color: "white",
                            fontSize: "1.5em",
                            lineHeight: "1.4em",
                          }}
                        >
                          Accessible Luxury
                          <br />
                          that'll evoke
                          <br />
                          your senses.{" "}
                        </h1> */}
                      </div>
                      <VideoPopup />
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>

        </div>

        <Footer2 />
      </Fragment>
    );
  }
}
export { GalleryBox };
export default Portfolio1;
