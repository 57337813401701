import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import logo1 from "./../../images/logo/logo1.png";
import logo2 from "./../../images/logo/logo2.png";
import logo3 from "./../../images/logo/logo3.png";
import logo4 from "./../../images/logo/logo4.png";
import logowhite from "./../../images/logo1.png";

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <footer className="site-footer" id="sidenav_footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div
                  className="col-md-4 wow fadeInLeft"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <Footercol1 />
                </div>
                <div
                  className="col-md-4 wow fadeInLeft"
                  data-wow-duration="2s"
                  data-wow-delay="0.6s"
                >
                  <Footercol2 />
                </div>
                <div
                  className="col-md-4 wow fadeInLeft"
                  data-wow-duration="2s"
                  data-wow-delay="0.9s"
                >
                  <Footercol3 />
                </div>
              </div>
            </div>
          </div>
          {/*  footer bottom part */}
          <div className="footer-bottom">
            <Footerbottom />
          </div>
        </footer>
      </Fragment>
    );
  }
}
function Footercol1() {
  return (
    <>
      <div className="widget widget_about">
        <div className="footer-logo">
          <Link to="./">
            <img src={logowhite} alt="" />
          </Link>
        </div>
        <div className="dlab-social-icon">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/khetanrealtygroup"
                className="fa fa-facebook mr-1"
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // Improves security
              ></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/khetanrealty"
                className="fa fa-instagram"
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // Improves security
              ></a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCz9DqeRdYVzKrXQY9-WkbVg"
                className="fa fa-youtube"
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // Improves security
              ></a>
            </li>
            <li>
              <a
                href="https://x.com/khetanrealty?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor&mx=2"
                className="fa fa-twitter"
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // Improves security
              ></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/khetanrealty/"
                className="fa fa-linkedin"
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // Improves security
              ></a>
            </li>
            {/* <li>
              <Link to={"#"} className="fa fa-instagram"></Link>
            </li> */}
          </ul>
        </div>
        <br />
        <span style={{fontSize: '0.8em', letterSpacing: '1px'}}>© 2024 Khetan Realty. All Rights Reserved Cross Country Realtors LLP </span>{" "}
      </div>
      {/* <p>
        The Content of this Website, including all images, are Artistic
        Conceptualization and do not replicate the Exact Product. To uphold our
        Commitment to Quality, Services, Infrastructure, Product, Designs,
        Amenities and Facilities mentioned herein, including Site Plans, Layout
        Plans, Floor Plans, Areas, Dimensions, Specifications, Elevations and
        Perspective Views are tentative and subject to Variation and
        Modification without Prior Notice. Additionally, Soft Furniture,
        Furniture and Gadgets depicted in Images are not included
        in the Offering.
      </p> */}
    </>
  );
}

function Footercol3() {
  return (
    <>
      <div className="widget">
        <h5 className="footer-title">Get in touch</h5>
        <ul className="contact-info-bx">
          <li>
            <i className="las la-map-marker"></i>
            <strong>Address</strong> SCO 11, 2nd Floor, Sector 80, Airport Road,
            Mohali
          </li>
          <li>
            <i className="las la-phone-volume"></i>
            <strong>Call :-</strong> +91-98784 33379
          </li>
          <li>
            <i class="las la-envelope-square"></i>
            <strong>Email :-</strong> info@khetanrealty.com
          </li>
        </ul>
      </div>
    </>
  );
}

function Footercol2() {
  return (
    <>
      <div className="widget">
        <h5 className="footer-title">Quick Links</h5>
        <ul>
        <li>
            <Link to={"/"}>Home</Link>
          </li>
          <li>
            <Link to={"/aboutus"}>About Us</Link>
          </li>
          <li>
            <Link to={"/ongoing-projects"}>Property</Link>
          </li>
          
          <li>
            <Link to={"/career"}>Career</Link>
          </li>
          <li>
            <Link to={"/contact-us"}>Contact Us</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

function Footerbottom() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 text-md-left text-center">
            {" "}
            {/* <span>© 2024 Khetan Realty. All Rights Reserved. </span>{" "} */}
            <p
                style={{
                  // fontStretch: "expanded",
                  fontSize: '0.8em',
                  fontWeight: '300',
                  textAlign: 'center',
                  lineHeight: '1.8em',
                  letterSpacing: '0.1em',
                  fontFamily: 'system-ui',
                }}
              >
                The Content of this Website, including all images, are Artistic
                Conceptualization and do not replicate the Exact Product. To
                uphold our Commitment to Quality, Services, Infrastructure,
                Product, Designs, Amenities and Facilities mentioned herein,
                including Site Plans, Layout Plans, Floor Plans, Areas,
                Dimensions, Specifications, Elevations and Perspective Views are
                tentative and subject to Variation and Modification without
                Prior Notice.
              </p>
          </div>
          <div className="col-md-6 col-sm-12 text-md-right text-center">
            {/* <div className="widget-link ">
              <ul>
                <li>
                  <Link to={"/about-us-1"}> About</Link>
                </li>
                <li>
                  <Link to={"/contact-us"}> Contact Us</Link>
                </li>
                <li>
                  <Link to={"/privacy-policy"}> Privacy Policy</Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export { Footercol1, Footercol2, Footercol3, Footerbottom };
export default Footer;
