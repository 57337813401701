import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import Header from "./../Layout/Header";
import Footer2 from "./../Layout/Footer2";
import PageTitle1 from "./../Layout/PageTitle1";
import ExhibitionSlider1 from "./../Element/ExhibitionSlider1";
import ExhibitionSlider2 from "./../Element/ExhibitionSlider2";
import aboutus from "./../../images/about/About.jpg";
import vision from "./../../images/about/Vision.jpg";
import mission from "./../../images/about/Mission.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import { Helmet } from "react-helmet";

class CompanyExhibition extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return (
      <Fragment>
        	<Helmet>
					<title>Khetan realty - About us</title>
					<meta name="About Us: Transforming Mohali's Real Estate - Vision & Mission" content="Discover how we're reshaping Mohali's real estate landscape at Khetan Realty. Learn about our vision and mission to deliver exceptional developments." />
				</Helmet>
        <Header />
        <div className="page-content bg-white">
          <PageTitle1 motherMenu="ABOUT US" activeMenu="ABOUT US" headerclassname={"dlab-bnr-inr1"} />
          <section className="content-inner exhibition-bx">
            <ExhibitionSection />
          </section>
        </div>
        <Footer2 />
      </Fragment>
    );
  }
}

function ExhibitionSection() {
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6">
          <ScrollAnimation animateIn="bounceInLeft" duration="3" animateOnce={true} animatePreScroll={false}>
            <img
              className="img-fluid"
              src={aboutus}
              alt="Aboutus"
            />
          </ScrollAnimation>
        </div>
        <div className="col-md-6">
          <ScrollAnimation animateIn="bounceInRight" duration="3" animateOnce={true} animatePreScroll={false}>
            <div style={{ marginTop: '5em' }}>
              <h3 className="title"> Khetan Realty </h3>
              <p style={{ fontSize: '1em', fontWeight: '300' }}>
                Envelopes the bigger, brighter side of life with its matchless projects and endows you unparalleled luxury & paves the way to a blissful life. It is a group that strives for brilliance and is driven by one of the most ambitious and focused developers who aims to serve with the best value. It stands atop a legacy crafted with Dedication and Excellence that brings joys and happiness to many families. The journey is marked by building class-apart structures and thriving communities across the Region. It aims to develop spaces keeping in mind the different needs of the different communities. At Khetan Realty we aim to stay among the Elite Real Estate Developers fostering Long-lasting Relationships with our Clients, Partners and Communities at large. Additionally, we continuously thrive to deliver Top-notch Results across Diverse Residential & Commercial Offerings. It embarks its footprint with the successful delivery of Paradise 85.
              </p>
            </div>
          </ScrollAnimation>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-md-6">
          <div>
          <ScrollAnimation animateIn="bounceInLeft" duration="3" animateOnce={true} animatePreScroll={false}>

            <h3 className="title">OUR VISION</h3>
            <p style={{ fontSize: '1em', fontWeight: '300' }}>
              At Khetan realty, our vision is to revolutionize the real estate industry by creating a seamless, transparent, and user-centric platform that empowers buyers, sellers, and investors alike. We aim to blend cutting-edge technology with unparalleled customer service to simplify the property transaction process, making it more efficient and accessible for everyone. Our commitment is to provide comprehensive, real-time data and personalized insights, enabling informed decisions and fostering a sense of trust and confidence in every client. By prioritizing innovation, integrity, and excellence, we aspire to be the go-to resource for all real estate needs, transforming the way people experience property markets globally.
            </p>
            </ScrollAnimation>
          </div>
        </div>
        <div className="col-md-6">
        <ScrollAnimation animateIn="bounceInRight" duration="3" animateOnce={true} animatePreScroll={false}>
          <img
            className="img-fluid mobile-margin"
            src={vision}
            alt="Vision"
          />
          </ScrollAnimation>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-md-6">
        <ScrollAnimation animateIn="bounceInLeft" duration="3" animateOnce={true} animatePreScroll={false}>

          <img
            className="img-fluid mobile-margin"
            src={mission}
            alt="Mission"
          />
          </ScrollAnimation>
        </div>
        <div className="col-md-6">
          <div>
          <ScrollAnimation animateIn="bounceInRight" duration="3" animateOnce={true} animatePreScroll={false}>

            <h3 className="title">Our Mission</h3>
            <p style={{ fontSize: '1em', fontWeight: '300' }}>
              Our mission at Khetan realty is to redefine the real estate experience by offering a dynamic, user-friendly platform that connects buyers, sellers, and investors with their ideal properties. We are dedicated to delivering exceptional value through innovative technology, expert guidance, and a deep understanding of the market. By fostering transparency, efficiency, and trust, we strive to simplify the property search and transaction process, ensuring every client feels empowered and confident in their real estate journey. Our goal is to build lasting relationships and make a positive impact on communities by helping people find not just a property, but a place to call home.
            </p>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ExhibitionSection };

export default CompanyExhibition;
