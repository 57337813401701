import React, { Fragment, Component } from "react";
import WOW from "wowjs";
import Header from "./../../Layout/Header";
import Footer2 from "./../../Layout/Footer2";
import PageTitle1 from "./../../Layout/PageTitle1";
import Mapview from "./../../Element/Mapview";
import axios from "axios";

//images/

import bgimg from "./../../../images/bg-view.png";
import ScrollAnimation from "react-animate-on-scroll";
import { Helmet } from "react-helmet";

const GoogleMap = () => {
  const iframeStyles = {
    width: '600px',
    height: '500px',
    border: '0',
    scrolling: 'no',
    marginHeight: '0',
    marginWidth: '0',
    overflow: 'hidden',
    background: 'none!important'
  };

  const containerStyles = {
    position: 'relative',
    textAlign: 'right',
    height: '500px',
    width: '600px'
  };

  const canvasStyles = {
    overflow: 'hidden',
    background: 'none!important',
    height: '500px',
    width: '600px'
  };

  return (
    <div style={containerStyles}>
      <div style={canvasStyles}>
        <iframe
          width="600"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=khetanrealty&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          style={iframeStyles}
          title="Google Map"
        ></iframe>
      </div>
    </div>
  );
};


class ContactUs1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      msg: '',
      message: '',
      messageType: '' // 'success' or 'error'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }


  handleSubmit = async (e) => {
    e.preventDefault();
    const reqObj = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email,
      message: this.state.msg,
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/email/contact`, reqObj, {
        // withCredentials: true, // If you need to send cookies
        headers: {
            'Content-Type': 'application/json',
        }})

      console.log(response);
      
      if (response.status === 200) {
        this.setState({
          message: "Your application has been submitted",
          messageType: 'success'
        });
      }

    } catch (error) {
      console.error('Error sending email:', error.response.data);
      this.setState({
        message: error?.response?.data ?? "Email sending failed",
        messageType: 'error'
      });
    }
  };


  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    const { message, messageType } = this.state;
    return (
      <Fragment>
         <Helmet>
					<title>Khetan realty - Career</title>
					<meta name="Contact Us - Reach Out to Khetan Realty" content="Have questions or inquiries? Contact Khetan Realty for assistance. We're here to help with any queries you may have.
" />
				</Helmet>
        <Header />
        <div className="page-content bg-white">
          <PageTitle1 motherMenu="Contact Us" activeMenu="Contact Us" headerclassname={"dlab-bnr-inr3"} />
          {/*  Inner Page Banner */}
          <section
            className="content-inner-1"
          // data-content="CONTACT US"
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className="section-head m-b30">
                    {/* <h2 className="title">Do You Have Any Question?</h2> */}
                    {/* <div className="dlab-separator bg-primary"></div> */}
                    <h3 className="title-small">CONTACT US</h3>
                  </div>
                  <ScrollAnimation
                    animateIn="bounceInLeft"
                    duration="2"
                    animatePreScroll={false}
                    animateOnce={true}
                  >
                    <ul className="contact-question">
                      <li>
                        <i className="fa fa-map-marker"></i>
                        <h4 className="title">Address</h4>
                        <p>SCO 11, 2nd Floor, Sector 80, Airport Road, Mohali</p>
                      </li>
                      <li>
                        <i className="fa fa-envelope-o"></i>
                        <h4 className="title">Email</h4>
                        <p>info@khetanrealty.com</p>
                      </li>
                      <li>
                        <i className="fa fa-phone"></i>
                        <h4 className="title">Phone</h4>
                        <p>+91-98784 33379</p>
                      </li>
                      {/* <li>
                      <i className="fa fa-fax"></i>
                      <h4 className="title">Fax</h4>
                      <p>+000 987 6543 321</p>
                    </li> */}
                    </ul>
                  </ScrollAnimation>
                </div>

                <div className="banner-map" style={{ width: "66%", border: '1px solid' }}>
                  <div class="mapouter" style={{
                    "position": "relative",
                    "textAlign": "right",
                    "height": "500px",
                    "width": "100%"
                  }}>
                    <div class="gmap_canvas" style={{
                      "overflow": "hidden",
                      "background": 'none !important',
                      "height": '500px',
                      "width": '100%'
                    }}>
                      <iframe
                        width="100%"
                        height="500"
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=khetanrealty&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                      <a href="https://embedgooglemap.net/124/">123movies</a>
                      <br />

                      <a href="https://www.embedgooglemap.net">embedgooglemap.net</a>
                    </div>
                  </div>

                </div>
             <div className="contact-box dzForm" style={{marginTop: '3em', width: '100%'}}>
                
                {message && (
                  <div 
                    className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-danger'}`} 
                    role="alert" 
                    style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', backgroundColor: messageType === 'success' ? '#d4edda' : '#f8d7da', color: messageType === 'success' ? '#155724' : '#721c24' }}
                  >
                    {messageType === 'success' ? (
                      <span>&#10004; {message}</span>
                    ) : (
                      <span>&#10060; {message}</span>
                    )}
                  </div>
                )}
                </div>
                <form
                  className="contact-box dzForm p-a30 border-1"
                  // action="script/contact.php"
                  style={{ marginTop: '1em' }}
                >
                
                  <h3 className="title-box">Contact us</h3>
                  {/* <ScrollAnimation
                    animateIn="fadeIn"
                    duration="2"
                    delay={2}
                    animatePreScroll={false}
                    animateOnce={true}
                  > */}
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="firstName"
                            type="text"
                            required
                            className="form-control"
                            placeholder="First Name*"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="lastName"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Last Name*"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="phone"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Phone*"
                            value={this.state.phone}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            required
                            placeholder="Your Email Id*"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <div className="input-group">
                          <textarea
                            name="msg"
                            rows="4"
                            className="form-control"
                            required
                            placeholder="Message....."
                            value={this.state.msg}
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group form-recaptcha">
                        <div className="input-group">
                          <div
                            className="g-recaptcha"
                            data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                            data-callback="verifyRecaptchaCallback"
                            data-expired-callback="expiredRecaptchaCallback"
                          ></div>
                          <input
                            className="form-control d-none"
                            style={{ display: "none" }}
                            data-recaptcha="true"
                            required
                            data-error="Please complete the Captcha"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 text-center">
                      <button
                        name="submit"
                        type="submit"
                        value="Submit"
                        className="btn btn-primary btn-lg"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  {/* </ScrollAnimation> */}
                </form>

              </div>
            </div>
          </section>

        </div>
        <Footer2 />
      </Fragment>
    );
  }
}
export default ContactUs1;
