import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

const PageTitle1 = ({ motherMenu, activeMenu, headerclassname }) => {
  return (
    <ScrollAnimation animateIn="fadeIn" duration="4">
    <div className={"dlab-bnr-inr"+" " +headerclassname}>
    {/* <div className="dlab-bnr-inr dlab-bnr-inr1"> */}
      <div className="container">
        <div className="dlab-bnr-inr-entry">
        <ScrollAnimation animateIn="fadeIn" delay={3} duration="1">
          <h1>{motherMenu}</h1>
          </ScrollAnimation>
          <nav aria-label="breadcrumb" className="breadcrumb-row">
            {/* <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"#"}>
                  <i className="las fa-home mr-2"></i>Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {activeMenu}
              </li>
            </ul> */}
          </nav>
        </div>
      </div>
    </div>
    </ScrollAnimation>
  );
};

export default PageTitle1;
