import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import Header from "./../../Layout/Header";
import Footer2 from "./../../Layout/Footer2";
import PageTitle1 from "./../../Layout/PageTitle1";
import { handleEmailSubmit } from "../Email/Email";
import axios from "axios";
import { Helmet } from "react-helmet";

//images/
import bgimg from "./../../../images/bg-view.png";
import ScrollAnimation from "react-animate-on-scroll";

class ContactUs2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      position: '',
      resume: null,
      message: '',
      messageType: '' // 'success' or 'error'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleFileChange = (e) => {
    this.setState({
      resume: e.target.files[0]
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('first_name', this.state.firstName);
    data.append('last_name', this.state.lastName);
    data.append('phone', this.state.phone);
    data.append('email', this.state.email);
    data.append('position', this.state.position);
    data.append('resume', this.state.resume);

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/email/career`, data);
        console.log(response);
        if(response.status === 200) {
          this.setState({
            message: "Your application has been submitted",
            messageType: 'success'
          });
        }

    } catch (error) {
        console.error('Error sending email:', error.response.data);
        this.setState({
          message: error?.response?.data ?? "Email sending failed",
          messageType: 'error'
        });
    }
  };

  render() {
    const { message, messageType } = this.state;

    return (
      <Fragment>
         <Helmet>
					<title>Khetan realty - Career</title>
					<meta name="Join Our Team - Explore Exciting Career Opportunities" content="Explore rewarding career opportunities at Khetan Realty. Join us and grow your career in real-estate industry. Apply today!
" />
				</Helmet>
        <Header />
        <div className="page-content bg-white">
          <PageTitle1 motherMenu="Career" activeMenu="Career" headerclassname={
            "dlab-bnr-inr2"
          } />
          <section className="content-inner-2">
            <div className="contact-box dzForm">
            {message && (
                  <div 
                    className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-danger'}`} 
                    role="alert" 
                    style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', backgroundColor: messageType === 'success' ? '#d4edda' : '#f8d7da', color: messageType === 'success' ? '#155724' : '#721c24' }}
                  >
                    {messageType === 'success' ? (
                      <span>&#10004; {message}</span>
                    ) : (
                      <span>&#10060; {message}</span>
                    )}
                  </div>
                )}
                
                
            </div>
            
            <div
              className="col-lg-8 col-md-7 col-sm-12 m-b30"
              style={{ display: "contents" }}
            >
              <form
                className="contact-box dzForm p-a30 border-1"
                onSubmit={this.handleSubmit}
              >
                <ScrollAnimation
                  animateIn="fadeIn"
                  duration="2"
                  animatePreScroll={false}
                  animateOnce={true}
                >
                  <h3 className="title-box" style={{ textTransform: 'capitalize', textAlign: 'center' }}>
                  Empowering Your Path to Success
                  </h3>
                </ScrollAnimation>
                <div className="dzFormMsg m-b20"></div>
                {/* <ScrollAnimation
                  animateIn="fadeIn"
                  duration="4"
                  delay={2}
                  animatePreScroll={false}
                  animateOnce={true}
                > */}
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="firstName"
                            type="text"
                            required
                            id="name"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                            className="form-control"
                            placeholder="Enter First Name*"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="lastName"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Enter Last Name*"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="phone"
                            type="text"
                            required
                            value={this.state.phone}
                            onChange={this.handleChange}
                            className="form-control"
                            placeholder="Enter Phone*"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.email}
                            required
                            placeholder="Enter Your Email Id*"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="position"
                            type="text"
                            value={this.state.position}
                            onChange={this.handleChange}
                            className="form-control"
                            required
                            placeholder="Enter Position applied for*"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="resume"
                            accept=".pdf,.doc,.docx"
                            type="file"
                            className="form-control"
                            required
                            placeholder="Position applied for"
                            onChange={this.handleFileChange}
                          />
                          
                        </div>
                        <strong>Max size: 1MB</strong>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group form-recaptcha">
                        <div className="input-group">
                          <div
                            className="g-recaptcha"
                            data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                            data-callback="verifyRecaptchaCallback"
                            data-expired-callback="expiredRecaptchaCallback"
                          ></div>
                          <input
                            className="form-control d-none"
                            style={{ display: "none" }}
                            data-recaptcha="true"
                            required
                            data-error="Please complete the Captcha"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 text-center">
                    <button
                      name="submit"
                      type="submit"
                      value="Submit"
                      className="btn btn-primary btn-lg"

                      onClick={this.handleSubmit}
                    >
                      APPLY NOW
                    </button>  
                    </div>
                    
                  </div>
                {/* </ScrollAnimation> */}
              </form>
            </div>
          </section>
        </div>
        <Footer2 />
      </Fragment>
    );
  }
}
export default ContactUs2;
